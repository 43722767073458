import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Page from '../page'

declare global {
  interface Window {
    disw: any,
  }
}

export default function Forbidden() {
  const { t } = useTranslation()

  useEffect(() => {
    const account = (window as Window).disw?.account

    if (account) {
      account.subscribeToUser((user: any, error: any) => {
        if (error) {
          console.error(error)
          localStorage.removeItem('token')
        } else if (user?.jwt) {
          localStorage.token = user.jwt
          window.location.reload()
        } else {
          account.login()
        }
      })
    }
  })

  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">{t('errors.forbidden.h1-1')}</h1>
        <p className="text-center">{t('errors.forbidden.p-1')}</p>
      </section>
    </Page>
  )
}
