import Page from '../page'
import { Trans, useTranslation } from 'react-i18next'

export default function Error() {
  const subject = `My Training Resources Issue`
  const body = `\n\nDescribe the issue that occurred: `
  const mailUrl = `mailto:SysAdmin_EDA.sisw@siemens.com?subject=${subject}&body=${body}`
  const { t } = useTranslation()

  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">{t('errors.error.h1-1')}</h1>
        <p className="text-center">
          <Trans
            i18nKey="errors.error.p-1"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href={mailUrl}></a>,
            }}
          />
        </p>
      </section>
    </Page>
  )
}
