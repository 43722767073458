import Page from '../page'
import { useTranslation } from 'react-i18next'

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <Page>
      <section className="bg-white p-x-lg p-y-md raised-xl">
        <h1 className="text-center">{t('errors.notFound.h1-1')}</h1>
        <p className="text-center">{t('errors.notFound.p-1')}</p>
      </section>
    </Page>
  )
}
